// import logo from './logo.svg';
import { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import axios from 'axios';

const apiKey = 'LMN1AN2VIC7YnhabZoai7UAJkqnahiGj';
const isProduction = false;

// const tmp = <div className="App">
// <header className="App-header">
  
//   <input type='text' className='App-input' ref={input} />

//   <button onClick={onClick} className='App-button'>Enviar</button>

//   <p>
//     {message}
//   </p>

//   <p>Parâmetros:</p>
//   <label>temperature</label>
//   <input
//     type='range'
//     ref={refTemperature}
//     onChange={(el) => refOutputTemperature.current.value = el.target.value}
//     min='0'
//     max='1'
//     step='0.01'
//     defaultValue='0.9'
//   />
//   <output ref={refOutputTemperature}>0.9</output>

//   <label>max_tokens</label>
//   <input
//     type='range'
//     ref={refMaxTokens}
//     onChange={(el) => refOutputMaxTokens.current.value = el.target.value}
//     min='1'
//     max='4000'
//     step='1'
//     defaultValue='150'
//   />
//   <output ref={refOutputMaxTokens}>150</output>

//   <label>top_p</label>
//   <input
//     type='range'
//     ref={refTopP}
//     onChange={(el) => refOutputTopP.current.value = el.target.value}
//     min='0'
//     max='1'
//     step='0.01'
//     defaultValue='1'
//   />
//   <output ref={refOutputTopP}>1</output>
  
//   <label>frequency_penalty</label>
//   <input
//     type='range'
//     ref={refFrequencyPenalty}
//     onChange={(el) => refOutputFrequencyPenalty.current.value = el.target.value}
//     min='0'
//     max='2'
//     step='0.01'
//     defaultValue='0'
//   />
//   <output ref={refOutputFrequencyPenalty}>0</output>

//   <label>presence_penalty</label>
//   <input
//     type='range'
//     ref={refPresencePenalty}
//     onChange={(el) => refOutputPresencePenalty.current.value = el.target.value}
//     min='0'
//     max='2'
//     step='0.01'
//     defaultValue='0.6'
//   />
//   <output ref={refOutputPresencePenalty}>0.6</output>

//   <label>best_of</label>
//   <input
//     type='range'
//     ref={refBestOf}
//     onChange={(el) => refOutputBestOf.current.value = el.target.value}
//     min='1'
//     max='20'
//     step='1'
//     defaultValue='10'
//   />
//   <output ref={refOutputBestOf}>10</output>
  
// </header>
// </div>

function App() {
  const [message, setMessage] = useState('Digite a mensagem e clique em enviar');
  const input = useRef(null);
  const refTemperature = useRef(null);
  const refOutputTemperature = useRef(null);
  const refMaxTokens = useRef(null);
  const refOutputMaxTokens = useRef(null);
  const refTopP = useRef(null);
  const refOutputTopP = useRef(null);
  const refFrequencyPenalty = useRef(null);
  const refOutputFrequencyPenalty = useRef(null);
  const refPresencePenalty = useRef(null);
  const refOutputPresencePenalty = useRef(null);
  const refBestOf = useRef(null);
  const refOutputBestOf = useRef(null);

  const onClick = useCallback(async () => {
    const question = input.current.value;
    const temperature = refTemperature.current.value;
    const frequency_penalty = refFrequencyPenalty.current.value;
    const max_tokens = refMaxTokens.current.value;
    const top_p = refTopP.current.value;
    const presence_penalty = refPresencePenalty.current.value;
    const best_of = refBestOf.current.value;
    
    if (question) {
      try {
        const response = await axios.get(`https://iwbsdc6v2f.execute-api.us-east-1.amazonaws.com/dev/chat?message=${question}&key=${apiKey}&temperature=${temperature}&frequency_penalty=${frequency_penalty}&max_tokens=${max_tokens}&top_p=${top_p}&presence_penalty=${presence_penalty}&best_of=${best_of}`);
        const { ai } = response.data;
        if (ai) {
          setMessage(ai);
        }
      } catch (error) {
        alert('ocorreu um erro');
        console.error(error);
      }
    }
  }, []);

  useEffect(() => {
    if (isProduction) {
      const pwd = prompt('Password: ');
      if (pwd !== 'luminor') {
        window.location = 'https://luminor.ai';
      }
    }
  }, []);

  return (
    <div></div>
  );
}

export default App;
